.error{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.error-container{
  display: flex;
  margin: 50px 0;
}

.error-img>img{
  width: 500px;
}
.error-text-field{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.error-text-field>h1{
  color: var(--text-color);
  font-size: 10rem;
  font-weight: 600;
}
.error-text-field>span{
  font-size: 22px;
  margin: 8px;
}
.error-text-field>a{
  margin-top: 30px;
  font-size: 20px;
  background-color: var(--button-color);
  padding: 12px 20px;
  border-radius: 10px;
  color: var(--text-color);
}
@media only screen and (max-width: 900px){
  .error-container{
    margin: 150px 0;
  }
  .error-img>img{
    width: 250px;
  }
  .error-text-field>h1{
    font-size: 8rem;
  }
  .error-text-field>span{
    text-align: center;
  }
  .error-text-field>a{
    margin: 10px 0;
  }
}
@media only screen and (max-width: 500px){
  .error-container{
    flex-direction: column;
    margin: 50px 0;
  }
  .error-img>img{
    margin: auto;
  }
  .error-text-field>h1{
    font-size: 5rem;
  }
}