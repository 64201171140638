.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 60px;
  position: sticky;
  top: 0px;
  z-index: 999;
  background-color: rgb(0, 0, 0, 0.3);
  backdrop-filter: blur(50px);
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.15);
}

.login-tab ion-icon{
  font-size: 40px;
  color:  var(--button-color);
  position: relative;
  right: 40px;
}
.mobile-search{
  display: none;
}
.logo > a {
  font-size: 40px;
  font-weight: 600;
}

.logo .white {
  color: var(--text-color) !important;
}

.logo .blue {
  color: var(--button-color);
}

.toggle {
  display: none;
}

.nav-links {
  display: flex;
  align-items: center;
  position: relative;
}

.nav-links > li {
  list-style: none;
}

.nav-links > li > a {
  text-decoration: none;
  margin: 0 20px;
  word-spacing: 3px;
  font-size: 17px;
  font-weight: 500;
}

.active {
  color: var(--button-color);
}

.login-btn{
  font-size: 40px;
  padding: 0px;
  color: var(--button-color);
  cursor: pointer;
}
.search > input {
  padding: 12px 20px;
  border-radius: 10px;
  background-color: var(--search-background);
  color: var(--text-color);
  width: 300px;
}
.search-submit{
  background: transparent;
  padding: 2px;
  color: var(--button-color);
  position: absolute;
  left: 86%;
  font-size: 20px;
  top: calc(35% - 10px);;
  bottom: 20px;
}
.search-submit-mobile{
  display: none;
}
.search-submit-mobile.active{
  display: block;
  position: absolute;
  bottom: calc(90% - 139px);
  right: 16%;
  font-size: 25px;
  background: transparent;
  z-index: 999;
}
.search-btn {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  position: relative;
  top: -1px;
  right: 35px;
}

.toggle-search {
  display: none;
}

.account-login{
  cursor: pointer;
}
.account-login img{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 9999;
}
.account-login .extra-options{
  display: inline;
  position: absolute;
  background:var(--account-login-background);
  padding: 30px;
  border-radius: 18px;
  right: 5%;
  margin-top: 10px;
  display: none;
  z-index: 99999;
}
.account-login .extra-options li{
  color: var(--text-color);
  font-size: 16px;
  margin-bottom: 10px;
}
.account-login .active{
  display: block;
}
@media only screen and (max-width: 1500px) {
  .header {
    padding: 25px 35px;
  }
}

@media only screen and (max-width: 1360px) {
  .search {
    position: relative;
    left: 25%;
  }
  .navbar-menu-btn {
    background: transparent;
    transition: 0.9s ease-in-out;
    position: relative;
  }

  .navbar-menu-btn span {
    display: block;
    background: var(--button-color);
    width: 35px;
    height: 3px;
    margin: 8px;
    transition: 0.5s ease-in-out;
  }

  .navbar-menu-btn .two {
    width: 30px;
  }

  .navbar-menu-btn .three {
    width: 25px;
  }
  .logo > a {
    font-size: 30px;
    padding-left: 10px;
  }

  .search {
    margin-right: 65px;
    z-index: -1;
  }

  .nav-links {
    display: none;
  }

  .toggle {
    display: block;
    position: absolute;
    top: 25px;
    right: 20px;
    cursor: pointer;
  }

  .toggle-links {
    position: absolute;
    width: 90%;
    margin-left: 30px;
    margin: auto;
    top: 100px;
    left: 0;
    right: 0;
    /* height: 200px; */
    flex-direction: column;
    padding: 15px;
    z-index: 9999;
    background-color: rgb(18, 26, 40);
    border-radius: 20px;
  }


  .toggle-links > li {
    margin: 15px 19px;
  }
}
@media(max-width:1360px){
  .account-login{
    position: relative;
    right: 5%;
  }
}
@media (max-width:1200px){
  .search {
    position: relative;
    left: 21%;
  }
}
@media (max-width:1000px){
  .search {
    position: relative;
    left: 17%;
  }
}
@media (max-width:900px){
  .search {
    position: relative;
    left: 10%;
  }
}
@media(max-width:850px){
  .account-login{
    position: relative;
    right: 6%;
  }
}
@media only screen and (max-width: 755px) {
  .header {
    padding-bottom: 20px;
  }
  .account-login{
    position: relative;
    left: 24%;
  }
  .login-tab ion-icon{
    font-size: 40px;
    position: relative;
  }
  .logo > a {
    font-size: 40px;
    position: relative;
  }
  .search {
    display: none;
  }
  .mobile-search{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
    margin-top: 3px;
    cursor: pointer;
  }
  .mobile-search .field-icon-search{
    width: 50px;
    font-size: 30px;
    color: var(--button-color);
    font-weight: 800;
  }
  .active-search-mobile{
    display: none;
  }
  .active-search-mobile.active{
    position: static;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    overflow: hidden;
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 14px;
    background-color: var(--search-background);
    color: #fff;
  }

  .mobile-view-search{
    display: none;
  }
  .toggle-search {
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    overflow: hidden;
    position: absolute;
    top: 350px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 9999;
    border-radius: 14px;
  }



  .toggle-links {
    height: 280px;
  }
}

@media only screen and (max-width: 750px) {
  .login-tab ion-icon{
    font-size: 35px;
    left: calc(25vw - 15px);
  }
  .logo > a {
    font-size: 35px;
  }
}
@media only screen and (max-width: 680px) {
  .login-tab ion-icon{
    font-size: 35px;
    left: 130px;
  }
  .account-login{
    position: relative;
    left: 22%;
  }
}
@media only screen and (max-width: 650px) {
  .account-login{
    position: relative;
    left: 20%;
  }
  .login-tab ion-icon{
    font-size: 35px;
    right: 0;
  }
}
@media only screen and (max-width: 623px) {
  .account-login{
    position: relative;
    left: 18%;
  }
  .login-tab ion-icon{
    font-size: 35px;
    left: 90px;
  }
}
@media only screen and (max-width: 560px) {
  .login-tab ion-icon{
    font-size: 35px;
    left: 80px;
  }
}
@media only screen and (max-width: 555px) {
  .account-login{
    position: relative;
    left: 13%;
  }
  .logo a{
    position: relative;
    right:20px;
  }
  .login-tab ion-icon{
    left: 60px;
  }
}

@media(max-width: 500px){
  .account-login{
    position: relative;
    left: 11%;
  }
  .login-tab ion-icon{
    left: calc(14vw - 10px);
  }
  .logo >a{
    font-size: 30px;
  }
}
@media(max-width:450px){
  .account-login{
    position: relative;
    left: 9%;
  }
  .login-tab ion-icon{
    left: calc(10vw - 10px);
  }
}
@media(max-width:432px){
  .account-login{
    left: 7%;
  }
  .login-tab ion-icon{
    font-size: 35px;
    left: calc(10vw - 10px);
  }
}
@media(max-width:405px){
  .account-login{
    left: calc(6vw - 15px);
  }
  .login-tab ion-icon{
    font-size: 35px;
    left: calc(6vw - 10px);
  }
  .logo>a{
    font-size: 30px;
  }
}
@media(max-width:377px){
  .account-login{
    left:calc(6vw - 10px);
  }
  .login-tab ion-icon{
    font-size: 35px;
    left: calc(6vw - 10px);
  }
  .logo>a{
    font-size: 23px;
  }
}
@media(max-width:360px){
  .account-login{
    left:4%;
  }
}
@media(max-width:340px){
  .account-login{
    left:2%;
  }
  .login-tab ion-icon{
    left: 0px;
  }
}
@media(max-width:321px){
  .account-login{
    left:calc(10vh - 62px);
  }
}