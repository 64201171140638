.terms-div{
    padding: 10px 70px;
    padding-bottom: 90px;
    font-size: 90%;
}
.terms-div .wrap-terms{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    font-weight: 500;
    font-size: 12px;
    flex-direction: column;
}
.terms-div .wrap-terms a {
    padding: 15px 30px;
    background: var(--button-color);
    margin-top: 40px;
    border-radius: 8px;
    color: var(--text-color);
    font-size: 15px;
}
.terms-div li{
    list-style-type: decimal-leading-zero;
    margin-left: 50px;
}
.terms-div h2{
    margin-top: 30px;
}

@media(max-width:500px){
    .terms-div{
        padding: 0px 10px;
    }
    .terms-div h1{
        font-size: 18px;
    }
    .terms-div h2{
        font-size: 17px;
    }
    .terms-div li{
        font-size: 12px;
        margin-left: 20px;
    }
    .terms-div .wrap-terms a {
        background: rgb(33, 150, 243);
        color: #fff;
    }
}
