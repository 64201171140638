:root {
    --background-color: hsl(225, 25%, 7%);
    --button-color: rgb(33, 150, 243);
    --scroll-bar: rgb(33, 150, 243);
    --footer-heading: rgb(33, 150, 243);
    --episode-button-color: rgb(51, 51, 154);
    --stream-background-color: rgb(18, 26, 40);
    --episode-note-color: rgb(129, 174, 220);
    --upcoming-season-background: #162032;
    --chatbot-chat: rgb(33, 150, 243, 70%);
    --card-background:  #162032;
    --search-background:  #162032;
    --account-login-background: #162032;;
    --episode-summary: #162032;
    --image-search-url: #162032;
    --footer-social-btn : #162032;
    --airing-shedule-background:  #162032;
    --airing-shedule-btn: rgb(0, 0, 0, 0.3);
    --text-color: #fff;
    --bookmark-bg: #000;
    --image-search-delete : #000;
    --image-search-another-image : #000;
    --footer-bg: #000;
    --comment-bg:rgb(22, 32, 50);
    --login-bg:hsl(220, 47%, 20%);
    --genre-filer-bar:hsl(218, 39%, 14%);
    --genre-background : #162032;
    --top-scroll-btn : hsl(219, 38%, 15%);
    --upcoming-season-bg: #162032;
}